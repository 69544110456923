import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import Structure from '../../components/structure';
import {Button} from '../../components/button';
import {NotificationsSvg} from '../../components/svg/notifications-svg';
import {AccessTimeSvg} from "../../components/svg/access-time-svg";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {getShortViewRequest, setNotifiesRequest, updateStatusesRequest, getServerTimeRequest} from '../../actions/transactions';
import ReactJson from 'react-json-view';
import {Loader} from '../../components/loaders/loader';
import Modal from '@mui/material/Modal';
import { NotifyModal } from '../../components/notify-modal';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Input} from '../../components/input';
import {format} from 'date-fns';
import Snackbar from '@mui/material/Snackbar';
import { InfoPopup } from "../../components/info-popup";
import {useParams, Link, useNavigate} from 'react-router-dom';
import {isJson} from '../../utils';
import {CopyClipboard} from '../../components/clipboard-copy';
import { AccessDeniedAlert } from "../../components/access-denied";
import {NotFoundData} from '../not-found-page/data-not-found';


const PageContainer = styled('div')(({theme}) => ({
    padding: '40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const ShortViewHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    '@media(max-width: 1250px)' : {
        flexWrap: 'wrap'
    }
});

const ShortViewHeaderText = styled('span')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const ShortViewHeaderLeft = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '24px',

    '@media(max-width: 1250px)' : {
        justifyContent: 'flex-start',
        width: '100%',
        marginBottom: '32px'
    },

    '@media(max-width: 991px)' : {
        flexWrap: 'wrap',
    }
});

const ShortViewHeaderRight = styled('div')({
    display: 'flex',
    gap: '16px'

});

const IconButtonWrapper = styled('div')({
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center'
});

const ShortViewServerTime = styled('div')(({ theme }) => ({
    fontSize: '14px',
    marginBottom: '32px',
    color: theme.palette.text.primary,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    '&.MuiTab-root': {
        color: '#979799'
    },
    '&.Mui-selected': {
        color: theme.palette.text.primary
    }
}));

const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: '#272F3D',
    },
});

const StyledTabPanel = styled(TabPanel)({
    padding: '0px',
    marginTop: '32px'
});

const ArrowContainer = styled('div')({
    cursor: 'pointer',
    marginRight: '24px',
    display: 'flex',
    alignItems: 'center'
});

const HeaderLeftContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',

    '@media(max-width: 991px)' : {
        width: '100%',
    }
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};

    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
        &:first-of-type{
            border-left: none;
        }
    }
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    border-bottom: none;
`));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '500px',
    marginRight: '16px',
    marginTop: '20px',
    '& label.Mui-focused': {
        color: '#38AA72',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#38AA72',
        },
    },

    '@media(max-width: 768px)' : {
        width: '100%',
        marginRight: '0px',
        marginTop: '20px'
    }
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
        color: '#38AA72'
    }
}));

const StatusInfoItem = styled('div')({
    marginBottom: '18px'
});

const StatusInfoContainer = styled('div')({
    'div': {
        '&:last-child': {
            marginBottom: '0px'
        }
    }
});

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const StyledLink = styled(Link)(({ theme }) => (`
    text-decoration: none;
`));

const WrapperCellRelative = styled('div')({
    position: 'relative',
});

const WrapperCoopyAbsolute = styled('div')({
    position: 'absolute',
    top: '0px',
    right: '0px',
});


const ShortView: React.FC<any> = ({
    getShortViewRequest,
    shortView,
    isLoading,
    setNotifiesRequest,
    updateStatusesRequest,
    notifiesInfo,
    statuses,
    getServerTimeRequest,
    serverTime,
    timezoneCurrent,
    errorMessage,
    routsUser,
    userRoles,
    isSuperadmin,
}) => {
    const theme = useTheme();
    let ReactJsonStyle = theme.palette.mode === 'dark' ? {backgroundColor: '#272930'} : null;
    const jsonViewTheme = theme.palette.mode === 'light' ? 'bright:inverted' : 'eighties';

    const accessedRoleShortView = routsUser.some(rout => rout.child === '/transaction/shortview') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleView = routsUser.some(rout => rout.child === '/transaction/view') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleNotify = routsUser.some(rout => rout.child === '/transaction/notify') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
    const accessedRoleUpdateStatus = routsUser.some(rout => rout.child === '/transaction/update-status') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;


    const {id} = useParams();
    const navigate = useNavigate();

    const [notifyModalOpen, setNotifyModalOpen] = useState(false);
    const handleNotifyModalOpen = () => {
        setNotifyModalOpen(true);
    };

    const handleNotifyModalClose = () => {
        setNotifyModalOpen(false);
    };

    const [newCallback, setNewCallback] = useState('');
    const handleChangeCallback = event => {
        setNewCallback(event.target.value);
    };

    const [callbackUrl, setCallbackUrl] = useState('');
    const handleSetCallbackUrl = event => {
        setCallbackUrl(event.target.value);
    };

    const handleSetNotify = () => {
        const url = Boolean(newCallback) ? callbackUrl : null;

        const data = {
            "callbackUrl": url
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        setNotifiesRequest([{id: shortView.id, data: JSON.stringify(data)}]).then(() => handleOpenNotifiesInfoSnackbar());
        handleNotifyModalClose();
    };

    const handleUpdateStatus = () => {
        updateStatusesRequest([shortView.id]).then(() => handleOpenStatusesSnackbar());
    }

    const [tabValue, setTabValue] = useState('1');
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const [openNotifiesInfoSnackbar, setOpenNotifiesInfoSnackbar] = React.useState(false);

    const handleOpenNotifiesInfoSnackbar = () => {
        setOpenNotifiesInfoSnackbar(true);
    };

    const handleCloseNotifiesInfoSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenNotifiesInfoSnackbar(false);
    };

    const [openStatusesSnackbar, setOpenStatusesSnackbar] = React.useState(false);

    const handleOpenStatusesSnackbar = () => {
        setOpenStatusesSnackbar(true);
    };

    const handleCloseStatusesSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenStatusesSnackbar(false);
    };

    useEffect(() => {
        if(accessedRoleShortView){
            getShortViewRequest(id);
            getServerTimeRequest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezoneCurrent]);

    let notifyStatusInfo = '';
    if(shortView.notifyStatus === 0) notifyStatusInfo = 'Waiting'
    if(shortView.notifyStatus === 1) notifyStatusInfo = 'Error'
    if(shortView.notifyStatus === 2) notifyStatusInfo = 'Success'
    if(shortView.notifyStatus === 3) notifyStatusInfo = 'Final'

    return (
        <PageContainer>

            {!accessedRoleShortView && <AccessDeniedAlert/>}

            {accessedRoleShortView &&
                <>
                    {errorMessage?.response?.status !== 404 &&
                        <>
                            <ShortViewHeader>
                                <ShortViewHeaderLeft>
                                    <HeaderLeftContainer>
                                        <ArrowContainer>
                                            <ArrowBackSvg size="22px" onClick={() => navigate(-1)}/>
                                        </ArrowContainer>
                                        <ShortViewHeaderText
                                            data-testid="titleTranactionsShortView"
                                        >
                                            Short view
                                        </ShortViewHeaderText>
                                    </HeaderLeftContainer>
                                    <Structure/>
                                </ShortViewHeaderLeft>
                                <ShortViewHeaderRight>
                                    {accessedRoleUpdateStatus &&
                                        <Button 
                                            onClick={handleUpdateStatus}
                                            data-testid='buttonShortViewUpdate'
                                        >
                                            <IconButtonWrapper>
                                                <AccessTimeSvg size="22px" />
                                            </IconButtonWrapper>
                                            UPDATE
                                        </Button>
                                    }
                                    {accessedRoleNotify && 
                                        <Button 
                                            variant="outlined" 
                                            onClick={handleNotifyModalOpen}
                                            data-testid='buttonShortViewNotify'
                                        >
                                            <IconButtonWrapper>
                                                <NotificationsSvg fill="#38AA72" size="22px"/>
                                            </IconButtonWrapper>
                                            NOTIFY
                                        </Button>
                                    }
                                    {accessedRoleView &&
                                        <StyledLink to={`/transactions/view/${id}`}>
                                            <Button 
                                                variant="outlined"
                                                data-testid='buttonShortViewToViev'
                                            >
                                                VIEW
                                            </Button>
                                        </StyledLink>
                                    }
                                </ShortViewHeaderRight>
                            </ShortViewHeader>
                            <ShortViewServerTime>
                                {`Server Time: ${serverTime}`}
                            </ShortViewServerTime>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={tabValue}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <StyledTabs value={tabValue} onChange={handleChangeTab} aria-label="lab API tabs example">
                                            <StyledTab data-testid='tabShortViewTransactionData' label="TRANSACTION DATA" value="1" />
                                            <StyledTab data-testid='tabShortViewStatusHistory' label="STATUS HISTORY" value="2" />
                                        </StyledTabs>
                                    </Box>
                                    <StyledTabPanel value="1">
                                        <StyledPaperTable>
                                            <TableContainer component={'div'}>
                                                <Table>
                                                    <StyledTableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: '250px'}}>
                                                                <div style={{width: '250px'}}>Title</div>
                                                            </TableCell>
                                                            <TableCell>Value</TableCell>
                                                        </TableRow>
                                                    </StyledTableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>ID</TableCell>
                                                            <TableCell>{shortView.id}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Brand</TableCell>
                                                            <TableCell>{shortView.brand?.id}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Payment system</TableCell>
                                                            <TableCell>{shortView.paymentSystem?.name}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Way</TableCell>
                                                            <TableCell>{shortView.way}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Currency</TableCell>
                                                            <TableCell>{shortView.currency}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Amount</TableCell>
                                                            <TableCell>{Number(shortView.amount)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Refund</TableCell>
                                                            <TableCell>{Number(shortView.refund)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Write off</TableCell>
                                                            <TableCell>
                                                                {
                                                                    shortView.writeOff ? Number(shortView.writeOff) : ''
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Commission Payer</TableCell>
                                                            <TableCell>{shortView.commissionPayer}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Payment method</TableCell>
                                                            <TableCell>{shortView.paymentMethod}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Merchant transaction</TableCell>
                                                            <TableCell>{shortView.merchantTransactionId}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Buyer</TableCell>
                                                            <TableCell>{shortView.buyerId}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>External ID</TableCell>
                                                            <TableCell>{shortView.externalId}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Requisites</TableCell>
                                                            <TableCell>
                                                                <WrapperCellRelative>
                                                                    {
                                                                        isJson(shortView.requisites) 
                                                                        ? <ReactJson
                                                                            src={JSON.parse(shortView.requisites)}
                                                                            enableClipboard={false}
                                                                            displayDataTypes={false}
                                                                            style={ReactJsonStyle}
                                                                            theme={jsonViewTheme}
                                                                        />
                                                                        : shortView.requisites
                                                                    }
                                                                    { shortView.requisites &&
                                                                        <WrapperCoopyAbsolute>
                                                                            <CopyClipboard data={shortView.requisites}/>
                                                                        </WrapperCoopyAbsolute>
                                                                    }
                                                                </WrapperCellRelative>  
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Callback data</TableCell>
                                                            <TableCell>
                                                                <WrapperCellRelative>
                                                                    {
                                                                        isJson(shortView.callbackData) 
                                                                        ? <ReactJson
                                                                            src={JSON.parse(shortView.callbackData)}
                                                                            enableClipboard={false}
                                                                            displayDataTypes={false}
                                                                            style={ReactJsonStyle}
                                                                            theme={jsonViewTheme}
                                                                        />
                                                                        : shortView.callbackData
                                                                    }
                                                                    { shortView.callbackData &&
                                                                        <WrapperCoopyAbsolute>
                                                                            <CopyClipboard data={shortView.callbackData}/>
                                                                        </WrapperCoopyAbsolute>
                                                                    }
                                                                </WrapperCellRelative> 
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>{shortView.status}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Notify status</TableCell>
                                                            <TableCell>
                                                                {notifyStatusInfo}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Comment</TableCell>
                                                            <TableCell>{shortView.comment}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Result data</TableCell>
                                                            <TableCell>
                                                                <WrapperCellRelative>
                                                                    {
                                                                        isJson(shortView.resultData)
                                                                        ? <ReactJson
                                                                            src={JSON.parse(shortView.resultData)}
                                                                            enableClipboard={false}
                                                                            displayDataTypes={false}
                                                                            style={ReactJsonStyle}
                                                                            theme={jsonViewTheme}
                                                                        />
                                                                        : shortView.resultData
                                                                    }
                                                                    { shortView.resultData &&
                                                                        <WrapperCoopyAbsolute>
                                                                            <CopyClipboard data={shortView.resultData}/>
                                                                        </WrapperCoopyAbsolute>
                                                                    }
                                                                </WrapperCellRelative>    
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Query data</TableCell>
                                                            <TableCell>
                                                                <WrapperCellRelative>
                                                                    {
                                                                        isJson(shortView.queryData)
                                                                        ? <ReactJson
                                                                            src={JSON.parse(shortView.queryData)}
                                                                            enableClipboard={false}
                                                                            displayDataTypes={false}
                                                                            style={ReactJsonStyle}
                                                                            theme={jsonViewTheme}
                                                                        />
                                                                        : shortView.queryData
                                                                    }
                                                                    { shortView.queryData &&
                                                                        <WrapperCoopyAbsolute>
                                                                            <CopyClipboard data={shortView.queryData}/>
                                                                        </WrapperCoopyAbsolute>
                                                                    }
                                                                </WrapperCellRelative>    
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Urls</TableCell>
                                                            <TableCell>
                                                                <WrapperCellRelative>
                                                                    {
                                                                        isJson(shortView.urls)
                                                                        ? <ReactJson
                                                                            src={JSON.parse(shortView.urls)}
                                                                            enableClipboard={false}
                                                                            displayDataTypes={false}
                                                                            style={ReactJsonStyle}
                                                                            theme={jsonViewTheme}
                                                                        />
                                                                        : shortView.urls
                                                                    }
                                                                    { shortView.urls &&
                                                                        <WrapperCoopyAbsolute>
                                                                            <CopyClipboard data={shortView.urls}/>
                                                                        </WrapperCoopyAbsolute>
                                                                    }
                                                                </WrapperCellRelative>    
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Created</TableCell>
                                                            <TableCell>{shortView.createdAt}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Updated</TableCell>
                                                            <TableCell>{shortView.updatedAt}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </StyledPaperTable>
                                    </StyledTabPanel>
                                    <StyledTabPanel value="2">
                                        <StyledPaperTable>
                                            <TableContainer component={'div'}>
                                                <Table>
                                                    <StyledTableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: '250px'}}>
                                                                <div>Status</div>
                                                            </TableCell>
                                                            <TableCell>Updated</TableCell>
                                                        </TableRow>
                                                    </StyledTableHead>
                                                    <TableBody>
                                                        {shortView?.historyTransStatuses?.map((row, index) => {
                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell>{row.status}</TableCell>
                                                                    <TableCell>{format(new Date(row.updatedAt), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </StyledPaperTable>
                                    </StyledTabPanel>
                                </TabContext>
                            </Box>
                            <Modal 
                                    open={notifyModalOpen}
                                    onClose={handleNotifyModalClose}
                                >
                                    <div>
                                        <NotifyModal
                                            title="Update status"
                                            confirmButtonText='SEND NOTIFY'
                                            cancelButtonText='CANCEL'
                                            onClose={handleNotifyModalClose}
                                            width="500px"
                                            onConfirm={handleSetNotify}
                                            onCancel={handleNotifyModalClose}
                                        >
                                            To update status on the platform click on the “send notify” or insert a new Callback URL
                                            <StyledFormControl>
                                                <RadioGroup
                                                    name="radio-buttons-group"
                                                    onChange={handleChangeCallback}
                                                    value={newCallback}
                                                >
                                                    <StyledFormControlLabel value="" control={<StyledRadio />} label="Current Callback" />
                                                    <StyledFormControlLabel value="true" control={<StyledRadio />} label="New Callback" />
                                                </RadioGroup>
                                            </StyledFormControl>
                                            {Boolean(newCallback) && <Input sx={{marginTop: '20px'}} onChange={handleSetCallbackUrl} value={callbackUrl} placeholder="New URL"></Input>}
                                        </NotifyModal>
                                    </div>             
                            </Modal>
                            <Snackbar open={openNotifiesInfoSnackbar} autoHideDuration={6000} onClose={handleCloseNotifiesInfoSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                                {notifiesInfo.length ? 
                                <InfoPopup
                                    severity="info"
                                    headerText="Notify info"
                                >
                                    <StatusInfoContainer>
                                        {notifiesInfo.map((item) => {
                                            return <StatusInfoItem key={item.id}>{`ID: ${item.id} - ${item.status}`}</StatusInfoItem>
                                        })}
                                    </StatusInfoContainer>
                                </InfoPopup> :
                                <InfoPopup
                                    severity="error"
                                    headerText="No data"
                                    infoText={errorMessage ? errorMessage.toString() : "Something went wrong. Please try again"}
                                />}
                            </Snackbar>
                            <Snackbar open={openStatusesSnackbar} autoHideDuration={6000} onClose={handleCloseStatusesSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                                {statuses.length ?
                                <InfoPopup
                                    severity="info"
                                    headerText="Notify info"
                                >
                                    <StatusInfoContainer>
                                        {statuses.map((item) => {
                                            return <StatusInfoItem key={item.id}>{`ID: ${item.id} - ${item.status}`}</StatusInfoItem>
                                        })}
                                    </StatusInfoContainer>
                                </InfoPopup> :
                                <InfoPopup
                                    severity="error"
                                    headerText="No data"
                                    infoText={errorMessage ? errorMessage.toString() : "Something went wrong. Please try again"}
                                />}
                            </Snackbar>
                        </>
                    }
                    {errorMessage?.response?.status === 404 && 
                        <>
                            <ShortViewHeader sx={{position:'relative', zIndex: '100'}}>
                                <ShortViewHeaderLeft>
                                    <HeaderLeftContainer>
                                        <ArrowContainer>
                                            <ArrowBackSvg size="22px" onClick={() => navigate(-1)}/>
                                        </ArrowContainer>
                                        <ShortViewHeaderText
                                            data-testid="titleTranactionsShortView"
                                        >
                                            Short view
                                        </ShortViewHeaderText>
                                    </HeaderLeftContainer>
                                </ShortViewHeaderLeft>
                            </ShortViewHeader>

                            <NotFoundData />
                        </>
                    }
                </>
            }
            {isLoading && <Loader />}
        </PageContainer>
    );
};

export default compose(
    connect((state: RootState | any) => ({
        isLoading: state.transactions.isLoading,
        shortView: state.transactions.shortView,
        notifiesInfo: state.transactions.notifiesInfo,
        statuses: state.transactions.statuses,
        serverTime: state.transactions.serverTime,
        timezoneCurrent: state.currentUser.userData.timezone,
        errorMessage: state.transactions.errorMessage,
        userRoles: state.currentUser.userData.roles,
        routsUser: state.currentUser.userData.routs,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        getShortViewRequest, 
        setNotifiesRequest, 
        updateStatusesRequest, 
        getServerTimeRequest
    })
)(ShortView);
