import {ActionTypes} from '../reducers/authorization';
import {ActionCurrentUserTypes} from '../reducers/currentUser';
import {temezoneListRequest} from './timezone';
import {getNodesTreeRequest, setActiveNodeRequest} from './nodeManagement';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionTypes.FETCH_LOGIN_START
});

export const requestSuccess = userInfo => {
	return {
		type: ActionTypes.FETCH_LOGIN_SUCCESS,
		payload: userInfo
	};
};

export const successDashboardNode = () => {
	return {
		type: ActionTypes.FETCH_LOGIN_DASHBOARD_SUCCESS
	}
}

export const requestFailure = error => {
	localStorage.removeItem('token')
	
	return {
		type: ActionTypes.FETCH_LOGIN_FAILURE,
		payload: error
	}
};

export const currentUserGet = (currentUser) => {
	return {
		type: ActionCurrentUserTypes.FETCH_CURRENT_USER_SUCCESS,
		payload: currentUser,
	}
}

export const currentUserSetTimezone = (zone) => {
	return {
		type: ActionCurrentUserTypes.FETCH_CURRENT_USER_SET_TIMEZONE,
		payload: zone,
	}
}

export const currentUserRequest = () => dispatch => {
	return API.currentUser
		.currentUser()
		.then(response => {
            dispatch(currentUserGet(response.data))
		})
		.catch(error => dispatch(requestFailure(error.message)));
};

export const loginRequest = data => (dispatch, getState) => {
	dispatch(requestStart());
    
	return API.login
		.logIn(data)
		.then(response => {
			localStorage.setItem('token', response.data.token);
            dispatch(currentUserRequest());
			dispatch(requestSuccess(response.data.data));
			dispatch(temezoneListRequest());
		})
		.then(() => {
			dispatch(getNodesTreeRequest())
				.then(() => {
					const state = getState();
					const idFirstNode = state.nodeManagement.nodesTree[0]?.children[0]?.id;
					dispatch(setActiveNodeRequest(idFirstNode))			
						.then(() => {
								setTimeout(() => {
									dispatch(successDashboardNode());
								}, 4000);
						})
				});
		})
		.catch(error => dispatch(requestFailure(error.message)));
};
