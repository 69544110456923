// @ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import { styled } from '@mui/material/styles';
import { EditRounded } from "../svg/edit-rounded";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {CloseSvg} from '../svg/close-svg';
import {SearchSvg} from "../svg/search-svg";
import {Button} from '../button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {FolderSvg} from '../svg/folder-svg';
import {DoneSvg} from '../svg/done-svg';
import useResizeObserver from "use-resize-observer";
import {Tree} from 'react-arborist';
import {useTreeModel} from '../../hooks/useTreeModel';
import {AccountBalanceSvg} from '../svg/account-balance-svg';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {connect} from 'react-redux';
import {compose} from "redux";
import {getNodesTreeRequest, setActiveNodeRequest} from '../../actions/nodeManagement';
import {getArrayWithBreadcrumbs} from '../../utils';

const ChipWrapper = styled('div')({
    height: '22px',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    gap: '12px',
    border: '1px solid #E3E8EC',
    borderRadius: '20px',
    '@media(max-width: 768px)' : {
        height: '14px',
        fontSize: '12px',
    }
})

const EditIconWrapper = styled('div')({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
});

const StructureContainer = styled('div')(({ theme }) => ({
    height: '100%',
    width: '550px',
    background: theme.palette.background.paper,
    position: 'absolute' as 'absolute',
    top: '50%',
    right: '0',
    transform: 'translate(0, -50%)',
    padding: '32px',

    '@media(max-width: 768px)' : {
        height: '100%',
        width: '85%',
    }
}));

const CloseSvgWrapper = styled('div')({
    position: 'absolute',
    top: '57px',
    right: '25px',
    cursor: 'pointer'
});

const Title = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    marginTop: '48px',
    marginBottom: '12px',
    color: theme.palette.text.primary,
}));

const StyledInput = styled('input')(({ theme }) => ({
    width: '100%',
    marginRight: '20px',
    boxSizing: 'border-box',
    height: '36px',
    paddingLeft: '20px',
    paddingRight: '38px',
    border: `1px solid ${theme.palette.mode === 'dark' ? '#FFFFFF' : '#C0C0C0'}`,
    borderRadius: '4px',
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    ':focus': {
        outline: '#1C1F25'
    }
}));

const InputWrapper = styled('div')({
    position: 'relative',
    zIndex: '100',
    marginBottom: '12px'
});

const InputIcon = styled('div')({
    padding: '4px',
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    right: '4px',
    transform: 'translateY(-50%)'
});

const StructureButtons = styled('div')({
    position: 'absolute',
    bottom: '64px',
});

const DoneIconContainer = styled('div')({
    position: 'absolute',
    top: '50%',
    right: '20px',
    transform: 'translate(0, -50%)',
    display: 'flex',
    alignItems: 'center'

});

const ChipLabel = styled('div')(({ theme }) => ({
    fontSize: '15px',
    fontWeight: '400',
    color: theme.palette.text.primary,
}));

const TreeItemContainer = styled('div')(({ selected, theme }) => ({
    border: `1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'}`,
    borderBottom: 'none !important',
    paddingLeft: '0px',
    background: selected ? theme.palette.action.selected : 'transparent',
    color: theme.palette.text.primary,
    width: 'calc(100% - 2px) !important',

    ':last-child': {
        borderBottom: `1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'} !important`,
    }
}));

const TreeItemRowContents = styled('div')({
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
});

const LeftBlock = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
});

const TreeItemIcon = styled('div')({
    marginRight: '8px',
    marginLeft: '10px'
});

const TreeItemLabel = styled('div')({
    fontSize: '14px',
    fontWeight: '400'
});

const StructureListContainer = styled('div')({
    paddingTop: '12px',
    overflow: 'scroll',
    height: '72%'
});

const BreadcrumbItem = styled('div')({
    color: '#979799',
    fontSize: '14px',
    fontWeight: '400'
});

const StructureListItemContainer = styled('div')({
    marginTop: '24px',
    borderBottom: '1px solid #E3E8EC',
    cursor: 'pointer'
});

const StructureListItem = styled('div')(({ theme, selected }) => ({
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.primary,
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    background: selected ? '#F1F8ED' : 'transparent',
}));

const StructureListItemIcon = styled('div')({
    marginRight: '8px'
});

const IconWrapper = styled('div')({
    position: 'absolute',
    left: '-18px'
});

const MaybeToggleButton = ({ toggle, isOpen, isFolder, isSelected, idTest }: any) => {
    if (isFolder) {
        const Icon = isOpen ? ExpandMoreIcon : ChevronRightIcon;
        return (
            <IconWrapper
                data-testid={`togglerNode_${idTest}`}
            >
                <Icon 
                    onClick={toggle}
                />
            </IconWrapper>
        );
    } else {
        return <div className="spacer" />;
    }
};


const Structure = ({getNodesTreeRequest, nodesTree, setActiveNodeRequest, activeNode, userID}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const {data, onToggle} = useTreeModel(nodesTree[0] ? nodesTree[0] : {});

    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

    const [searchValue, setSearchValue] = React.useState('');
    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);
    };

    const [selectedId, setSelectedId] = useState('');
    const handleSelect = () => {
        setActiveNodeRequest(selectedId);
        setSearchValue('');
        handleClose();
    };

    useEffect(() => {
        // getNodesTreeRequest();

        // if (!activeNode.id) {
        //     setActiveNodeRequest(userID === 248 ? 328 : 1);
        // };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const itemsToShowInSearch = useMemo(() => {
        return getArrayWithBreadcrumbs(nodesTree).filter(item =>
            item.name?.toUpperCase().includes(searchValue.toUpperCase()) || String(item.id).includes(searchValue));
    }, [searchValue, nodesTree]);

    const user248Nodes = getArrayWithBreadcrumbs(nodesTree).filter(item => {
        const allowedNodes = [11, 462, 227, 255, 267, 328, 335, 382, 51, 167, 183, 398, 57, 165];
        return allowedNodes.includes(item.id);
    });

    // console.log('data', data);
    // console.log('nodesTree', nodesTree);

    return (
        <>
            <ChipWrapper>
                {activeNode.type === 1 ? <AccountBalanceSvg size="20px"/> : <FolderSvg width="20px" height="16px" />}
                <ChipLabel
                    data-testid="titleActiveNodeStructure"
                >
                    {`${activeNode.id}. ${activeNode.name}`}
                </ChipLabel>
                <EditIconWrapper
                    data-testid="buttonOpenNodeStructure"
                >
                    <EditRounded size="24px" onClick={handleOpen}/>
                </EditIconWrapper>
            </ChipWrapper>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box>
                    <StructureContainer>
                        <CloseSvgWrapper>
                            <CloseSvg size="14px" onClick={handleClose}/>
                        </CloseSvgWrapper>
                        <Title>Structure</Title>
                        {userID === 248 
                        ? 
                            <StructureListContainer>
                                {user248Nodes.map(item => 
                                    <StructureListItemContainer onClick={() => setSelectedId(item.id)}>
                                        <Breadcrumbs separator="›">
                                            {item.breadcrumbs.filter(item => item !== '100500. undefined').map(item => {
                                                return <BreadcrumbItem>{item}</BreadcrumbItem>
                                            })}
                                        </Breadcrumbs>
                                        <StructureListItem isSelected={item.id === selectedId}>
                                            <StructureListItemIcon>
                                                {item.type === 1 ? <AccountBalanceSvg size="24px"/> : <FolderSvg size="24px" />}
                                            </StructureListItemIcon>
                                            {`${item.id}. ${item.name}`}
                                            {item.id === selectedId && 
                                            <DoneIconContainer>
                                                <DoneSvg size="24px"/>
                                            </DoneIconContainer>}
                                        </StructureListItem>
                                    </StructureListItemContainer>
                                )}
                            </StructureListContainer> 
                        :
                        <>
                            <InputWrapper>
                                <StyledInput 
                                    placeholder="Search" 
                                    onChange={handleChangeSearchValue}
                                    data-testid="inpitSearchSelectedStructure"
                                />
                                <InputIcon>
                                    <SearchSvg size="22px"/>
                                </InputIcon>
                            </InputWrapper>
                            {searchValue ? 
                            <StructureListContainer>
                                {itemsToShowInSearch.map((item, i) => {
                                    return item.id !== 100500 
                                        ? <StructureListItemContainer key={i} onClick={() => setSelectedId(item.id)}>
                                                <Breadcrumbs separator="›">
                                                    {item.breadcrumbs.filter(item => item !== '100500. undefined').map((item, ii) => {
                                                        return <BreadcrumbItem key={ii}>{item}</BreadcrumbItem>
                                                    })}
                                                </Breadcrumbs>
                                                <StructureListItem isSelected={item.id === selectedId}>
                                                    <StructureListItemIcon>
                                                        {item.type === 1 ? <AccountBalanceSvg size="24px"/> : <FolderSvg size="24px" />}
                                                    </StructureListItemIcon>
                                                    {`${item.id}. ${item.name}`}
                                                    {item.id === selectedId && 
                                                        <DoneIconContainer>
                                                            <DoneSvg size="24px"/>
                                                        </DoneIconContainer>
                                                    }
                                                </StructureListItem>
                                            </StructureListItemContainer>
                                        : <div key={i}></div>
                                })}
                            </StructureListContainer> :
                            <div ref={ref} style={{width: '100%', height: 'calc(100vh - 195px)', marginTop: '-42px'}}>
                                <Tree
                                    width={width}
                                    height={height}
                                    data={data}
                                    onToggle={onToggle}
                                    rowHeight={42}
                                    indent={48}
                                    openByDefault={false}
                                >{({
                                    innerRef,
                                    data,
                                    styles,
                                    state,
                                    handlers,
                                }) => {
                                    const folder = Array.isArray(data.children);
                                    const isOpen = !!state.isOpen;
                                                    
                                    return (
                                        <>
                                            { data.id !== 100500 &&
                                                <TreeItemContainer 
                                                    style={styles.row} 
                                                    ref={innerRef} 
                                                    onClick={() => setSelectedId(data.id)} 
                                                    selected={selectedId === data.id}
                                                >
                                                        <TreeItemRowContents className="row-contents" style={styles.indent}>
                                                            <LeftBlock>
                                                                <MaybeToggleButton
                                                                    toggle={handlers.toggle}
                                                                    isOpen={isOpen}
                                                                    isFolder={folder}
                                                                    isSelected={selectedId === data.id}
                                                                    idTest={data.id}
                                                                />
                                                                <TreeItemIcon>
                                                                    {data.type === 1 ? <AccountBalanceSvg size="24px"/> : <FolderSvg size="24px" />}
                                                                </TreeItemIcon>
                                                                <TreeItemLabel>
                                                                    {`${data.id}. ${data.name}`}
                                                                </TreeItemLabel>
                                                            </LeftBlock>
                                                            {selectedId === data.id &&
                                                                <DoneIconContainer
                                                                    data-testid={`markSelectedNode_${data.id}`}
                                                                >
                                                                    <DoneSvg size="24px"/>
                                                                </DoneIconContainer>
                                                            }
                                                        </TreeItemRowContents>
                                                </TreeItemContainer>
                                            }
                                        </>
                                    );
                                }}</Tree>
                            </div>}
                        </>
                        }
                        <StructureButtons>
                            <Button 
                                sx={{marginRight: '12px'}} 
                                onClick={handleSelect} 
                                disabled={!selectedId}
                                data-testid="buttonApplaySelectedStructure"
                            >
                                APPLY
                            </Button>
                            <Button 
                                variant="outlined" 
                                onClick={handleClose}
                                data-testid="buttonCancelSelectedStructure"
                            >
                                CANCEL
                            </Button>
                        </StructureButtons>
                    </StructureContainer>
                </Box>
            </Modal>
        </>
    )
};

export default compose(
    connect((state: RootState | any) => ({
        nodesTree: state.nodeManagement.nodesTree,
        activeNode: state.nodeManagement.activeNode,
        userID: state.currentUser.userData.id
    }), {getNodesTreeRequest, setActiveNodeRequest})
)(Structure);