import { useClipboard } from 'use-clipboard-copy';
import {Button} from '../../components/button';
import AddLinkIcon from '@mui/icons-material/AddLink';

export function CopyClipboardButton({ data, sx = {} }) {
    const clipboard = useClipboard();
  
    const handleClick = () => {
        clipboard.copy(data)
    };
  
    return (
        <Button
            variant="outlined"
            sx={sx}
            onClick={handleClick}
        >
            <AddLinkIcon/>
        </Button>
    );
}