import React, {useState} from "react";
import {Input} from '../../components/input';
import {Button} from '../../components/button';
import { styled } from "@mui/material/styles";
import {connect} from 'react-redux';
import {compose} from "redux";
import {loginRequest} from '../../actions/authorization';
import Box from '@mui/material/Box';
import {RootState} from '../../store/configure-store';
import { InfoPopup } from "../../components/info-popup";
import Snackbar from '@mui/material/Snackbar';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';


const InputWrapper = styled('div')({
    marginBottom: '20px',
    '& > .MuiFormControl-root': {
        margin: '0',
    }
});

const StyledBox = styled(Box)(({ theme }) => ({
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.paper,
    padding: '20px',
    boxSizing: 'border-box',
}));

const StyledBoxForm = styled('div')(({ theme }) => ({
    maxWidth: '400px',
    width: '100%',
}));

const LoginForm = ({
    loginRequest, 
    isError, 
    errorMessage,
}) => {

    const [state, setState] = useState({username: '', password: ''});

    const {username, password} = state;

    const handleChange = (prop: string) => (event: any) => {
        setState({...state, [prop]: event.target.value});
    };

    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleOpenSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        loginRequest({username, password}).then(() => handleOpenSnackbar());
    };

    return (
        <StyledBox>
            <StyledBoxForm>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <InputWrapper>
                        <Input
                            name="login"
                            label="Login"
                            type="text"
                            value={username}
                            onChange={handleChange('username')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                                autoComplete: 'off',
                                form: {
                                  autoComplete: 'off',
                                },
                            }}
                            inputProps={{
                                'data-testid': "inputLoginUser",
                            }}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <Input
                            name="password"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={handleChange('password')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <KeyIcon />
                                    </InputAdornment>
                                ),
                                autoComplete: 'new-password',
                                form: {
                                  autoComplete: 'off',
                                },
                            }}
                            inputProps={{
                                'data-testid': "inputPasswordUser",
                            }}
                        />
                    </InputWrapper>
                    <Button 
                        type="submit" 
                        sx={{width: '100%'}}
                        size="medium"
                        data-testid="buttonSubmitLoginUser"
                    >
                        LOGIN
                    </Button>
                </form>
            </StyledBoxForm>
            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                {isError ?
                    <InfoPopup
                        dataTestid='snakebarLoginPage'
                        severity="error"
                        headerText="Login error"
                        infoText={errorMessage ? errorMessage : "Something went wrong. Please try again"}
                        
                    />:
                null}
            </Snackbar>
        </StyledBox>
    )
}

export default compose(
    connect((state: RootState) => ({
        isError: state.authorization.isError,
        errorMessage: state.authorization.errorMessage,
    }), {
        loginRequest,
    }),
)(LoginForm)
