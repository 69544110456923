import React from "react";
import { styled } from "@mui/material/styles";
import PeopleIcon from '@mui/icons-material/People';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import LaptopChromebookOutlinedIcon from '@mui/icons-material/LaptopChromebookOutlined';
import { compose } from "redux";
import { connect } from 'react-redux';
import { RootState } from '../../store/configure-store';
import Structure from '../../components/structure';
import { LoaderMini } from "../../components/loaders/loader-mini";

import { dasboardIP } from '../../actions/ipAddress';
import { getBrandsRequest, getClientsRequest } from '../../actions/nodeManagement';
import { getPaymentSystemsAmountt } from '../../actions/paymentSystems';
import { getTransactionsAmountRequest } from '../../actions/transactions';

// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
// import { InfoOutlineSvg } from "../../components/svg/info-outline-svg";
// import TextField from '@mui/material/TextField';

const PageContainer = styled('div')(({theme}) => (`
    position: relative;
    padding: 40px;
    background: ${theme.palette.background.paper};
    box-shadow: none;
    @media(max-width: 768px){
        padding: 16px;
        height: 100%;
    }
`));

const DashboardMainHeader = styled('div')(`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 32px;
    @media (max-width: 991px) {
        justify-content: space-between;
    }
`);

const DashboardTitle = styled('div')(({theme}) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-right: 24px;
    color: ${theme.palette.text.primary};
    margin-bottom: 0px;
    @media (max-width: 991px) {
        margin-bottom: 0px;
    }
    @media(max-width: 768px){
        font-size: 20px;
    }
    @media(max-width: 540px){
        width: 100%;
        padding-bottom: 16px;
    }
`));

const DashboardWrapper = styled('div')(({theme}) => (`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
`));

const DashboarItemElementWrapp = styled('div')(({theme}) => (`
    position: relative;
    color: #fff;
    box-sizing: border-box;
    width: calc(25% - 24px);
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 4px;
    overflow: hidden;
    background: #38AA72;
    transition: all 333ms;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    &:hover{
        box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    }
    @media (max-width: 1320px) {
        width: calc(33.33% - 24px);
        &:last-child{
            margin-left: 36px;
            margin-right: auto;
        }
    }
    @media (max-width: 1260px) {
        width: calc(50% - 15px);
        &:last-child{
            margin-left: 0px;
        }
    }
    @media (max-width: 991px) {
        width: 100%;
        &:last-child{
            margin-bottom: 0px;
        }
    }
`));

const DashboarItemElementWrappCount = styled('div')(({theme}) => (`
    height: 44px;
    position: relative;
    font-size: 38px;
    font-weight: bold; 
    margin-bottom: 20px;   
    @media (max-width: 1600px) {
        font-size: 30px;
        height: 35px;
    }
    @media (max-width: 1400px) {
        font-size: 24px;
        margin-bottom: 15px;  
        height: 28px;
    }
`));

const DashboarItemElementWrappTitle = styled('div')(({theme}) => (`
    font-size: 18px;
    font-weight: 400;   
    @media (max-width: 1400px) {
        font-size: 16px; 
    } 
`));

const DashboarItemElementWrappIcon = styled('div')(({theme}) => (`
    position: absolute;
    right: 30px;
    bottom: 0; 
    height: 100%;
    transition: all 333ms;
    opacity: .2;
    svg{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;
    }
`));

const LoaderMiniWrapp = styled('div')(({theme}) => (`
    position: absolute;
    top:0;
    left:0;
`));

// const WrapperCascadesInfo = styled('div')(({theme}) => (`
//     width: 100%;
//     padding-top: 10px; 

//     @media(max-width: 767px){
//         padding-bottom: 20px;
//     }; 
// `));

// const CascadesTextInfo = styled('div')(({ theme }) => ({
//     fontSize: '30px',
//     fontWeight: '500',
//     lineHeight: '38px',
//     marginRight: '24px',
//     color: theme.palette.text.primary,

//     '@media(max-width: 768px)' : {
//         fontSize: '20px',
//     }
// }));

// const HeaderText = styled('div')({
//     display: 'flex',
//     marginBottom: '0px',

//     '@media(max-width: 768px)' : {
//         justifyContent: 'space-between',
//         marginBottom: '24px',
//     }
// });

// const StyledPaperTable = styled(Paper)(({ theme }) => (`
//     border-radius: 0;
//     border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
//     box-sizing: border-box;
//     box-shadow: none;
// `));

// const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
//     position: relative;
//     .MuiPaper-root{
//         border-radius: 0;
//         box-sizing: border-box;
//         box-shadow: none;
//     }
//     & .overlay-scroll-table{
//         max-height: calc(100vh - 550px);
//         @media(max-width: 767px){
//             max-height: calc(70vh);
//         }; 
//     }
// `));

// const StyledTableHead = styled(TableHead)(({ theme }) => (`
//     background: ${theme.palette.background.default};
//     position: sticky;
//     z-index: 10;
//     top: 0px;

//     & th{
//         border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
//         &:first-of-type{
//             border-left: none;
//         };
//         &:last-of-type{
//             // border-left: none;
//         };
//     }   
// `));

// const MappingPageInfoMessage = styled('div')({
//     marginTop: '8px',
//     gap: '10px',
//     display: 'flex',
//     alignItems: 'center',
//     marginBottom: '16px',
// });

// const MappingPageInfoMessageText = styled('div')(({ theme }) => ({
//     fontSize: '14px',
//     fontWeight: '400',
//     color: theme.palette.text.primary,
// }));

// const WrappInfoSettings = styled('div')({
//     width: '100%',
//     paddingBottom: '10px',
// });

// const WrappInfoSettingsItem = styled('div')({
//     width: '100%',
//     fontSize: '20px',
//     fontWeight: '400',
//     paddingBottom: '10px',
//     lineHeight: '100%',

//     '@media(max-width: 767px)' : {
//         fontSize: '16px',
//     }
// });

// const HeaderTextCascades = styled(HeaderText)(`
//     margin-bottom: 20px;
//     @media(max-width: 767px){
//         margin-bottom: 0;
//     }; 
// `)


// const listMc = {
//     cascades: [
//         {way:'Deposit', payment_system: "piastrix", currency: 'Usd', account: 'account[1]', method: 'method[2]', sum: 100500, balance: 1400500},
//         {way:'Deposit', payment_system: "privat24", currency: 'uah', account: 'account[32]', method: 'all', sum: 0, balance: 5000},
//         {way:'Deposit', payment_system: "tls", currency: 'usdt', account: 'account[7]', method: 'method[1]', sum: 10000, balance: 20000},
//         {way:'Deposit', payment_system: "piastrix", currency: 'eur', account: 'account[3]', method: 'method[1]',  sum: 10, balance: 5700},
//         {way:'Withdraw', payment_system: "tls", currency: 'usd', account: 'account[54]', method: 'method1[8]', sum: 0.0004, balance: 1.00500},
//     ],
//     notification: {
//         telegram: "987654321"
//     },
//     reset_time: 5580
// }




type RoleItem = {
    '@id': string,
    '@type': string,
    name: string,
}

type PropsType = {
    ip: string,
    isLoadingIp: boolean,
    brands: number,
    isLoadingBrands: boolean,
    clients: number,
    isLoadingClients: boolean,
    paymentSystems: number,
    isLoadingPaymentSystems: boolean,
    transactions: number,
    isLoadingTransactions: boolean,
    activeNode: number | any, //NideType, after add
    userCurrentRole: Array<RoleItem>,
    isLoadingNodeId: boolean,
    dasboardIP: () => void,
    getBrandsRequest: () => void,
    getClientsRequest: () => void,
    getPaymentSystemsAmountt: () => void,
    getTransactionsAmountRequest: () => void,
}

type StateType = {
    time?: string;
}

class Dashboard extends React.Component<PropsType, StateType> {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    };

    updateData = () => {
        const {
            dasboardIP,
            getBrandsRequest,
            getClientsRequest, 
            getPaymentSystemsAmountt,
            getTransactionsAmountRequest,
            userCurrentRole,
        } = this.props;

        dasboardIP();
        userCurrentRole.forEach(itm => {
            if(itm.name !== 'wallets01'){
                getBrandsRequest();
                getClientsRequest();
                getPaymentSystemsAmountt();
                getTransactionsAmountRequest();
            }
        });
    };

    componentDidMount() {
        this.updateData();
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeNode.id !== this.props.activeNode.id) {
            this.updateData();
        };
    };

    render() {

        const {
            ip,
            isLoadingIp,
            brands,
            isLoadingBrands,
            clients,
            isLoadingClients,
            paymentSystems,
            isLoadingPaymentSystems,
            transactions,
            isLoadingTransactions,
            userCurrentRole,
            isLoadingNodeId,
            // activeNode,
        } = this.props;
        
        // wallet01 -> role 'Client' on prod
        let isClientBlockInfo = userCurrentRole.some(role => role.name === 'wallets01');

        return (
            <PageContainer>
            <DashboardMainHeader>
                <DashboardTitle>Dashboard</DashboardTitle>
                <Structure/>
            </DashboardMainHeader>
            <DashboardWrapper>

                {!isClientBlockInfo && <DashboarItemElementWrapp>
                        <DashboarItemElementWrappCount>
                            {isLoadingNodeId
                                ? <>
                                    {   
                                        !isLoadingClients
                                            ? clients ? clients : 0
                                            : <LoaderMiniWrapp>
                                                <LoaderMini />
                                            </LoaderMiniWrapp>
                                    }
                                </>
                                : <LoaderMiniWrapp>
                                    <LoaderMini />
                                </LoaderMiniWrapp>
                            }
                        </DashboarItemElementWrappCount>
                        <DashboarItemElementWrappTitle>Clients</DashboarItemElementWrappTitle>
                        <DashboarItemElementWrappIcon>
                            <PeopleIcon/>
                        </DashboarItemElementWrappIcon>
                    </DashboarItemElementWrapp>
                }
                
                {!isClientBlockInfo && <DashboarItemElementWrapp>
                        <DashboarItemElementWrappCount>
                            {isLoadingNodeId
                                ? <>
                                    {
                                        !isLoadingBrands 
                                            ? brands ? brands : 0
                                            : <LoaderMiniWrapp>
                                                <LoaderMini />
                                            </LoaderMiniWrapp>
                                    }
                                </>
                                : <LoaderMiniWrapp>
                                    <LoaderMini />
                                </LoaderMiniWrapp>
                            }
                        </DashboarItemElementWrappCount>
                        <DashboarItemElementWrappTitle>Brands</DashboarItemElementWrappTitle>
                        <DashboarItemElementWrappIcon>
                            <ShoppingBasketIcon/>
                        </DashboarItemElementWrappIcon>
                    </DashboarItemElementWrapp>
                }
                
                {!isClientBlockInfo && <DashboarItemElementWrapp>
                        <DashboarItemElementWrappCount>
                            {isLoadingNodeId
                                ? <>
                                    {
                                        !isLoadingPaymentSystems 
                                            ? paymentSystems
                                            : <LoaderMiniWrapp>
                                            <LoaderMini />
                                        </LoaderMiniWrapp>
                                    }
                                </>
                                : <LoaderMiniWrapp>
                                    <LoaderMini />
                                </LoaderMiniWrapp>
                            }
                        </DashboarItemElementWrappCount>
                        <DashboarItemElementWrappTitle>Payment Systems</DashboarItemElementWrappTitle>
                        <DashboarItemElementWrappIcon>
                            <PaymentIcon/>
                        </DashboarItemElementWrappIcon>
                    </DashboarItemElementWrapp>
                }
                
                {!isClientBlockInfo && <DashboarItemElementWrapp>
                        <DashboarItemElementWrappCount>
                            {isLoadingNodeId 
                                ? <>
                                    {   
                                        !isLoadingTransactions 
                                            ? transactions 
                                            : <LoaderMiniWrapp>
                                                <LoaderMini />
                                            </LoaderMiniWrapp>
                                    }
                                </>
                                : <LoaderMiniWrapp>
                                    <LoaderMini />
                                </LoaderMiniWrapp>
                            }
                        </DashboarItemElementWrappCount>
                        <DashboarItemElementWrappTitle>Transactions for today</DashboarItemElementWrappTitle>
                        <DashboarItemElementWrappIcon>
                            <ReceiptLongOutlinedIcon/>
                        </DashboarItemElementWrappIcon>
                    </DashboarItemElementWrapp>
                }
                
                <DashboarItemElementWrapp>
                    <DashboarItemElementWrappCount>
                        {
                            !isLoadingIp 
                                ? ip
                                : <LoaderMiniWrapp>
                                    <LoaderMini />
                                </LoaderMiniWrapp>
                        }
                    </DashboarItemElementWrappCount>
                    <DashboarItemElementWrappTitle>Your IP</DashboarItemElementWrappTitle>
                    <DashboarItemElementWrappIcon>
                        <LaptopChromebookOutlinedIcon/>
                    </DashboarItemElementWrappIcon>
                </DashboarItemElementWrapp>

                {/* {(isClientBlockInfo) && */}
                {/* {false &&
                    <WrapperCascadesInfo>
                        <CascadesTextInfo>
                            <HeaderTextCascades> Cascades</HeaderTextCascades>
                            <WrappInfoSettings>
                                <WrappInfoSettingsItem>
                                    Telegram: {listMc.notification.telegram}
                                </WrappInfoSettingsItem>
                                <WrappInfoSettingsItem>
                                    Time to reset: 03:00
                                </WrappInfoSettingsItem>
                            </WrappInfoSettings>
                        </CascadesTextInfo>
                        <StyledPaperTable>                               
                            <StyledTableContainer>
                                <OverlayScrollbarsComponent
                                    className='overlay-scroll-table'
                                    options={{
                                        scrollbars: { visibility: 'visible' } 
                                    }}
                                >
                                    <Table sx={{minWidth: '920px'}}>
                                        <StyledTableHead>
                                            <TableRow>
                                                <TableCell>Way</TableCell>
                                                <TableCell sx={{whiteSpace: 'nowrap'}}>Payment system</TableCell>
                                                <TableCell>Account</TableCell>
                                                <TableCell>Method</TableCell>
                                                <TableCell>Currency</TableCell>
                                                <TableCell>Sum</TableCell>
                                            </TableRow>
                                        </StyledTableHead>
                                        <TableBody>
                                            {(listMc.cascades || []).map(row => {
                                                return (
                                                    <TableRow key={Math.random()}>
                                                        <TableCell>{row.way}</TableCell>
                                                        <TableCell>{row.payment_system}</TableCell>
                                                        <TableCell>{row.account}</TableCell>
                                                        <TableCell>{row.method}</TableCell>
                                                        <TableCell>{row.currency.toLocaleUpperCase()}</TableCell>
                                                        <TableCell>{row.sum}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </OverlayScrollbarsComponent>
                            </StyledTableContainer>     
                        </StyledPaperTable>
                    </WrapperCascadesInfo>
                } */}

            </DashboardWrapper>
        </PageContainer>
        ) 
    }
}

export default compose(
    connect( (state: RootState) => ({
        ip: state.ipAddress.ipData,
        isLoadingIp: state.ipAddress.isLoading,
        brands: state.nodeManagement.brands,
        isLoadingBrands: state.nodeManagement.isLoading,
        clients: state.nodeManagement.clients,
        isLoadingClients: state.nodeManagement.isLoading,
        paymentSystems: state.paymentSystems.paymentSystemsAmount,
        isLoadingPaymentSystems: state.paymentSystems.isLoading,
        transactions: state.transactions.transactionsAmount,
        isLoadingTransactions: state.transactions.isLoading,
        activeNode: state.nodeManagement.activeNode,
        userCurrentRole: state.currentUser.userData.roles,
        isLoadingNodeId: state.authorization.isLoadingDashboard,
    }), {
        dasboardIP,
        getBrandsRequest,
        getClientsRequest,
        getPaymentSystemsAmountt,
        getTransactionsAmountRequest,
    }),
)(Dashboard)
