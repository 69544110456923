import axios from 'axios';
import {store} from '../../store/configure-store';
import { baseURL } from './_axiosBaseUrl';
import {createRestartableTimer} from '../../utils';

const ENV_TIME_LOGOUTL = process.env.REACT_APP_LOGOUTL;

const baseApi = {
	get: async (path) => {
		return request({
			path,
			method: 'GET',
			body: undefined
		});
	},
	post: async (path, payload = {}) => {
		return request({
			path,
			method: 'POST',
			body: payload,
		});
	},
	delete: async (path, payload = {}) => {
		return request({
			path,
			method: 'DELETE',
			body: payload,
		});
	},
	patch: async (path, payload = {}) => {
		return request({
			path,
			method: 'PATCH',
			body: payload,
		});
	}
	//...
};
  
const timerLogout = createRestartableTimer(() => {
	store.dispatch({type: 'LOG_OUT',})
}, ENV_TIME_LOGOUTL);
  
timerLogout.start();

const handleResponse = (response) => {
	return response
		.then(res => {
			timerLogout.restart();
			return res;
		})
		.catch(error => {
			const response = error.response;

			if (response.status === 401) {
				console.log('😨😨😨', response.status)
				store.dispatch({type: 'LOG_OUT',})
			}

			if (response.status === 404) {
				console.log('😨😨😨', response.status)
			}

			throw error;
		});
};

const request = async ({ path, method, body }) => {
	const resp = axios({
		withCredentials: true,
		method: method,
		url: `${baseURL}${path}`,
		data: method !== 'GET' ? body : undefined,
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': method === 'PATCH' ? 'application/merge-patch+json' :
							method === 'POST'  ? 'application/ld+json' : 'application/json',
		},
	});
	return handleResponse(resp);
};

export default baseApi;
