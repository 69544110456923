export namespace actionTypes {
    export const FETCH_DASHBOARD_START = 'FETCH_DASHBOARD_START';
    export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
    export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';
};

export const initialState = {
	isError: false,
    isLoading: false,
	dashboardData: {},
	errorMessage: ''
};

export const dashboard = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.FETCH_DASHBOARD_START:
			return {
				...state,
				isLoading: true
			};		
		case actionTypes.FETCH_DASHBOARD_SUCCESS:
			return {
				...state,
				isLoading: true,
				errorMessage: ''
			};
		case actionTypes.FETCH_DASHBOARD_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};